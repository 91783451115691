<template>
  <div class="coin">
    <panel-title icon="el-icon-coin" title="云币明细" />

    <panel-wrap>
      <div class="account-info">
        <div class="avatar">
          <img src="http://dummyimage.com/60x60/39A2DB" alt="" />
        </div>
        <div class="info">
          <el-row :gutter="40">
            <el-col :span="12">
              <div class="title">深圳XXXXXXXXXX有限公司</div>
              <div class="desc">
                本公司主营软件开发、APP开发、小程序开发、高端高难度的应用开发、各种软件系统开发，顶尖技术团队，减少后顾之忧，保证您满意，欢迎咨询洽谈
              </div>
            </el-col>
            <el-col :span="12">
              <div class="bal-title">云币数量</div>
              <div class="balance">31</div>
            </el-col>
          </el-row>

          <el-link
            class="rule-link"
            type="primary"
            :underline="false"
            icon="el-icon-question
"
            >云币规则</el-link
          >
        </div>
      </div>
    </panel-wrap>

    <panel-content>
      <div slot="filter" class="filter">
        <span class="active">全部明细</span>
        <span>收获</span>
        <span>消耗</span>
      </div>

      <div class="table-wrap" slot="content">
        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <el-table-column label="用途/来源"> </el-table-column>

          <el-table-column prop="name" label="数量" width="120">
          </el-table-column>

          <el-table-column prop="name" label="时间" width="120">
          </el-table-column>

          <el-table-column prop="name" label="状态" width="120">
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pagination-wrap">
          <ori-pagination />
        </div>
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
import PanelWrap from "./PanelWrap.vue";
export default {
  components: { PanelTitle, PanelWrap, PanelContent },
};
</script>

<style lang="scss" scoped>
.coin {
  .account-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .avatar {
      cursor: pointer;
      flex: 0 0 auto;

      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      img {
        transition: all 0.8s ease-in;
      }

      &:hover,
      &:active {
        img {
          transform: scale(1.2);
        }
      }
    }

    .info {
      position: relative;
      flex: 1 1 auto;
      margin-left: 15px;

      .rule-link {
        position: absolute;
        right: 0px;
        top: 0px;
      }

      .title {
        cursor: pointer;
        color: #666666;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 8px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &:active {
          color: red;
        }
      }

      .desc {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 12px;
        line-height: 22px;
        height: 22px;
        color: #999;
      }

      .bal-title {
        color: #666;
      }

      .balance {
        color: #e62d31;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .filter {
    span {
      cursor: pointer;
      display: inline-block;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #ccc;

      font-size: 14px;

      line-height: 17px;

      &:hover,
      &:active,
      &.active {
        color: red;
      }
    }

    span:last-child {
      border-right: none;
    }
  }

  .table-wrap {
    margin-top: 18px;
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }

  .pagination-wrap {
    text-align: center;
    margin: 30px 0;
  }
}
</style>